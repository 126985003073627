<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal
      title="选择供应商"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-input-search
        placeholder="请输入供应商名称搜索"
        enter-button
        v-model="searchText"
        @search="onSearch"
      ></a-input-search>

      <Padding />

      <div class="title">
        <span>已选择</span>
        <a-icon
          type="close"
          class="refresh"
          @click="clearSelected"
          title="点我清空已选择"
        />
      </div>
      <a-space class="recent-selected-list">
        <a-tag
          color="blue"
          style="margin-bottom: 8px"
          v-for="item in selectedList"
          :key="item.id"
          :closable="true"
          @close="removeSelected(item)"
        >
          {{ item.company }}
        </a-tag>
      </a-space>
      <div class="right">
      <a-button class="right" @click="cancel" type="primary" style="margin-bottom: 5px;">确定</a-button>

      </div>

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
      >
        <a-table-column title="编号" data-index="id" />
        <a-table-column title="名称" data-index="company" />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchCompanyList } from "@/api/bidding/company";
export default {
  props:{
    msg:{
      type: Array,
      default:()=> []
    }
  },
  data() {
    return {
      visible: false,
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      
      searchText:'',

      selectedList: [], // 选中的公司
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
    msg(newValue){
      console.log(newValue)
      this.selectedList = newValue
    }
  },

  mounted(){
    this.getList()
  },

  methods: {
    getList(){
      this.loading = true;
      let {current,pageSize,searchText} = this
      
      fetchCompanyList({
        pageNum: current,
        pageSize,
        company: searchText
        })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list
            this.total = res.totalSize
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    onSearch(value) {
      this.searchText = value
      this.current = 1;
      this.getList();
    },

    onTableRowClick(record) {
      // 若选择过，则不添加
      if(this.selectedList.findIndex(item => item.id === record.id) === -1) {
        this.selectedList.push({
          id: record.id,
          company: record.company,
        });
      }
    },

    removeSelected(item){
      const list = this.selectedList;
      const index = list.findIndex((element) => element.id === item.id);
      list.splice(index, 1);
    },
    clearSelected(){
      this.selectedList = [];
    },

    cancel() {
      this.$emit("change", this.selectedList);
      this.visible = false;
      this.searchText = '';
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recent-selected-list {
  display: flex;
  flex-wrap: wrap;
}
</style>